import React from "react";
import { Link } from "react-router-dom";
import "./Home.css";
import con1 from "../images/con1.jpg";
import con2 from "../images/con2.jpg";
import con3 from "../images/con3.jpg";
import Contact from "../Contact";
///////////sponsors logo now///////////////
import unitedHospital from '../images/title sponsers/united-hospitals.jpg';
import eSignTree from "../images/platinum sponsers/esigntree.png";
import prestige from "../images/platinum sponsers/prestige.jpg";
import jswCement from "../images/gold sponsers/jsw-cement.jpg";
import luxuryRide from "../images/gold sponsers/luxury-ride.jpg";
import laxmiConstructions from "../images/silver sponsers/laxmi-constructions.jpg";
import kamachi from '../images/silver sponsers/kamachi.png';

const Home = () => {
  return (
    <div className="main-home-container bg-stone-200">
      <section className="text-gray-600 body-font sm:mt-10">
        <div className="bg mt-16"></div>
        <div className="container px-5 py-20 mt-16 md:py-5 flex flex-col flex-wrap items-center h-101 justify-center">
          <h1 className="title-main md:text-5xl text-4xl font-sans  font-bold title-font  mb-5 md:mt-2 md:mb-5 md:w-2/5 text-center flex flex-col items-center">
            ADVAYA
            <br />
          </h1>
          <span className="sub-title text-xl md:text-3xl mt-5 text-white z-10">
            11th edition of <br />
            <span className="main text-white font-sans text-5xl pt-0 font-bold md:text-10xl mb-5 md:mb-3">
              Concrete fair
            </span>
            <p className="text-sm md:text-xl mt-10 text-zinc-300 text-center sm:mt-5 z-10">
              A National Level Civil Engineering Fest
            </p>
          </span>
          <div className="mt-4 md:w-3/5 md:pl-6">
            

            <div className="flex mt-12 md:mt-8 justify-center ">
              <a
                href="#events"
                className="text-slate-300 border px-2 py-1 rounded-full hover:bg-gray-500 hover:text-slate-50 inline-flex items-center ml-4 text-md z-10"
              >
                Know More
                <svg
                  fill="none"
                  stroke="currentColor"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                  stroke-width="2"
                  className="w-4 h-4 ml-2"
                  viewBox="0 0 24 24"
                >
                  <path d="M5 12h14M12 5l7 7-7 7"></path>
                </svg>
              </a>
            </div>
          </div>
        </div>
      </section>

      <section className="text-gray-600 body-font mt-16">
        <div className="container px-5 py-1 mx-auto">
          <div className="flex flex-col text-center w-full mb-4">
            <h1 className="mile font-sans sm:text-3xl text-2xl font-semibold title-font text-gray-900 tracking-wide">
              MILESTONES
            </h1>
          </div>
          <div className="flex flex-wrap m-4 text-center">
            <div className="p-4 md:w-1/3 sm:w-1/2 w-full">
              <div className="px-4 py-6 rounded-lg">
                <h2 className="title-font font-medium text-3xl text-gray-900">
                  75+
                </h2>
                <p className="leading-relaxed text-red-500 font-medium">
                  Companies
                </p>
              </div>
            </div>
            <div className="p-4 md:w-1/3 sm:w-1/2 w-full">
              <div className="px-4 py-6 rounded-lg">
                <h2 className="title-font font-medium text-3xl text-gray-900">
                  300+
                </h2>
                <p className="leading-relaxed text-red-500 font-medium">
                  Colleges
                </p>
              </div>
            </div>
            <div className="p-4 md:w-1/3 sm:w-1/2 w-full">
              <div className="px-4 py-6 rounded-lg">
                <h2 className="title-font font-medium text-3xl text-gray-900">
                  10
                </h2>
                <p className="leading-relaxed text-red-500 font-medium">
                  Successfull conducts
                </p>
              </div>
            </div>
          </div>
        </div>
      </section>

      <section id="events" className="text-gray-600 body-font">
        <div className="container px-5 py-10 mx-auto flex flex-col items-center">
          <div className="flex flex-wrap w-full mb-6">
            <div className=" w-full mb-6 lg:mb-0">
              <h1 className="font-sans text-3xl font-semibold title-font text-gray-900 text-center tracking-wide">
                EVENTS
              </h1>
            </div>
          </div>
          <div className="flex flex-wrap -m-4 justify-center">
            <div className="xl:w-1/3 md:w-1/2 p-4">
              <Link to={"/events/sortium"}>
                <div className="p-3 rounded-lg border border-gray-400 bg-gray-200">
                  <img
                    className="h-40 rounded w-full object-cover object-center mb-6"
                    src={con1}
                    alt="content"
                  />
                  <h3 className="tracking-widest text-red-500 text-xs font-medium title-font">
                    11&12th Dec
                  </h3>
                  <h2 className="text-lg text-gray-900 font-medium title-font mb-4 flex justify-between">
                    CON.SORTIUM
                    <div className="text-red-500 inline-flex items-center text-sm">
                      Register
                      <svg
                        fill="none"
                        stroke="currentColor"
                        stroke-linecap="round"
                        stroke-linejoin="round"
                        stroke-width="2"
                        className="w-4 h-4 "
                        viewBox="0 0 24 24"
                      >
                        <path d="M5 12h14M12 5l7 7-7 7"></path>
                      </svg>
                    </div>
                  </h2>
                  <p className="leading-relaxed text-base">
                    "Sell me this pen"
                  </p>
                </div>
              </Link>
            </div>
            <div className="xl:w-1/3 md:w-1/2 p-4">
              <Link to={"/events/dwg"}>
                <div className="border border-gray-400 p-3 rounded-lg bg-gray-200">
                  <img
                    className="h-40 rounded w-full object-cover object-center mb-6"
                    src={con2}
                    alt="content"
                  />
                  <h3 className="tracking-widest text-red-500 text-xs font-medium title-font">
                    11&12th Dec
                  </h3>
                  <h2 className="text-lg text-gray-900 font-medium title-font mb-4 flex justify-between">
                    CON.DWG
                    <div className="text-red-500 inline-flex items-center text-sm">
                      {" "}
                      Register
                      <svg
                        fill="none"
                        stroke="currentColor"
                        stroke-linecap="round"
                        stroke-linejoin="round"
                        stroke-width="2"
                        className="w-4 h-4 "
                        viewBox="0 0 24 24"
                      >
                        <path d="M5 12h14M12 5l7 7-7 7"></path>
                      </svg>
                    </div>
                  </h2>
                  <p className="leading-relaxed text-base">
                    "The ultimate AutoCAD battle"
                  </p>
                </div>
              </Link>
            </div>
            <div className="xl:w-1/3 md:w-1/2 p-4">
              <Link to={"/events/quest"}>
                <div className="border border-gray-400 p-3 rounded-lg bg-gray-200">
                  <img
                    className="h-40 rounded w-full object-cover object-center mb-6"
                    src={con3}
                    alt="content"
                  />
                  <h3 className="tracking-widest text-red-500 text-xs font-medium title-font">
                    11&12th Dec
                  </h3>
                  <h2 className="text-lg text-gray-900 font-medium title-font mb-4 flex justify-between">
                    CON.QUEST
                    <div className="text-red-500 inline-flex items-center text-sm">
                      {" "}
                      Register
                      <svg
                        fill="none"
                        stroke="currentColor"
                        stroke-linecap="round"
                        stroke-linejoin="round"
                        stroke-width="2"
                        className="w-4 h-4 "
                        viewBox="0 0 24 24"
                      >
                        <path d="M5 12h14M12 5l7 7-7 7"></path>
                      </svg>
                    </div>
                  </h2>
                  <p className="leading-relaxed text-base">
                    "Fastest finger first"
                  </p>
                </div>
              </Link>
            </div>
          </div>
          <Link to="/events" className="text-zinc-900 inline-flex items-center mt-6 text-xl">
           
              More Events
              <svg
                fill="none"
                stroke="currentColor"
                stroke-linecap="round"
                stroke-linejoin="round"
                stroke-width="2"
                className="w-4 h-4 ml-2"
                viewBox="0 0 24 24"
              >
                <path d="M5 12h14M12 5l7 7-7 7"></path>
              </svg>
            
          </Link>
        </div>
      </section>

      <section id="sponsors" className="text-gray-600 body-font">
        <div className="container px-5 py-12 mx-auto">
          <div className="flex flex-col text-center w-full mb-2">
            <h1 className="text-3xl font-sans font-semibold title-font mb-4 text-gray-900">
              SPONSORS
            </h1>
          </div>
          <div className="sponsor-container-main flex flex-col justify-center items-center">
          <div className="xl:w-1/3 md:w-1/2 p-4">
              <div className="p-3">
                <h2 className="md:mx-2 p-2 mb-4 text-3xl text-center font-bold font-sans">
                  TITLE SPONSOR
                </h2>
                <div className="md:p-5">
                  <img
                    className="h-30 w-full object-center mb-10 border border-gray-600"
                    src={unitedHospital}
                    alt="content"
                  />
                 
                </div>
              </div>
            </div>
          <div className="sponsor-container flex flex-wrap m-5 justify-center">
            <div className="xl:w-1/3 md:w-1/2 md:p-4">
              <div className="md:p-4">
                <h2 className="md:mx-2 p-2 text-2xl mb-3 text-center font-bold font-sans">
                  PLATINUM SPONSORS
                </h2>
                <div className="p-5">
                  <img
                    className="h-30 w-full object-center mb-6 border border-gray-400"
                    src={eSignTree}
                    alt="content"
                  />
                  <img
                    className="h-30 w-full object-center mb-6 border border-gray-400"
                    src={prestige}
                    alt="content"
                  />
                </div>
              </div>
            </div>
            <div className="xl:w-1/3 md:w-1/2 p-4">
              <div className="p-3">
                <h2 className="md:mx-2 p-2 mb-3 text-2xl text-center font-bold font-sans">
                  GOLD SPONSORS
                </h2>
                <div className="md:p-5">
                  <img
                    className="h-30 w-full object-center mb-6 border border-gray-400"
                    src={jswCement}
                    alt="content"
                  />
                  <div className="bg-gray-500">
                  <img
                    className="h-10 w-full object-center mb-6 z-10"
                    src={luxuryRide}
                    alt="content"
                  /> 
                  </div>
                </div>
              </div>
            </div>
            <div className="xl:w-1/3 md:w-1/2 p-4">
              <div className="p-3">
                <h2 className="md:mx-2 p-2 mb-3 text-2xl text-center font-bold font-sans">
                  SILVER SPONSORS
                </h2>
                <div className="md:p-5">
                  <img
                    className="h-30 w-full object-center mb-6 border border-gray-400"
                    src={laxmiConstructions}
                    alt="content"
                  />
                  <img
                    className="h-30 w-full object-center mb-6 border border-gray-400"
                    src={kamachi}
                    alt="content"
                  />
                </div>
              </div>
            </div>
          </div>
          </div>
        </div>
      </section>
      <Contact/>
    </div>
  );
};

export default Home;
/*<button className="inline-flex text-white bg-zinc-900 border-white py-2 px-5 focus:outline-none hover:bg-transparent hover:text-black border hover:border-gray-600 transition rounded tracking-wider mx-4 text-sm">
<a href="register.html">Register</a>
</button>*/
