import React from 'react';
import './Footer.css';

const Footer = () => {
  return (
    <div className='main-footer-container'>
      <section className="text-zinc-100 body-font border-t-2 border-gray-700 overflow-hidden bg-zinc-800">
        <div className="container px-5 pt-5 mx-auto">
          <div className="flex flex-col text-center w-full mb-5 items-center">
            <h1 className="sm:text-4xl text-xl font-medium title-font  text-white">
              RV COLLEGE OF ENGINEERING
            </h1>
            <p className="slogan-container lg:w-2/3 mx-auto leading-relaxed text-base text-zinc-400">
              Go, Change the World
            </p>
            
            <a
              href="https://instagram.com/vinay_vidhehi?igshid=MzMyNGUyNmU2YQ=="
              className="developer-container lg:w-2/3 mx-auto leading-relaxed text-xs text-gray-300 mt-5"
            >
              Created by © DV Web Developers.
            </a>
          </div>
        </div>
      </section>
    </div>
  )
}

export default Footer