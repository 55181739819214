import React from "react";

const Contact = () => {
  return (
    <div className="contact-container">
      <section className="text-gray-600 body-font bg-stone-200">
        <div className="container px-5 py-14 mx-auto flex flex-col items-center justify-between">
          <div className="flex flex-col text-center w-full mb-5 md:mb-10">
            <h1 className="text-3xl font-bold font-sans title-font mb-4 text-gray-700">
              CONTACT US
            </h1>

            <p className="lg:w-2/3 mx-auto leading-relaxed text-base">
              Department of Civil Engineering
            </p>
          </div>
          <div className="flex md:flex-row md:w-2/4 md:justify-between items-center flex-col mb-5">
            <div className="flex flex-row items-center p-3">
              <svg
                fill="none"
                stroke="currentColor"
                stroke-linecap="round"
                stroke-linejoin="round"
                stroke-width="2"
                className="w-5 h-5"
                viewBox="0 0 24 24"
              >
                <rect width="20" height="20" x="2" y="2" rx="5" ry="5"></rect>
                <path d="M16 11.37A4 4 0 1112.63 8 4 4 0 0116 11.37zm1.5-4.87h.01"></path>
              </svg>
              <p className="mx-1">concretefair_2023</p>
            </div>
            <div className="flex flex-row items-center p-3">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                className="icon icon-tabler icon-tabler-brand-gmail"
                width="24"
                height="24"
                viewBox="0 0 24 24"
                stroke-width="2"
                stroke="currentColor"
                fill="none"
                stroke-linecap="round"
                stroke-linejoin="round"
              >
                {" "}
                <path stroke="none" d="M0 0h24v24H0z" fill="none" />{" "}
                <path d="M16 20h3a1 1 0 0 0 1 -1v-14a1 1 0 0 0 -1 -1h-3v16z" />{" "}
                <path d="M5 20h3v-16h-3a1 1 0 0 0 -1 1v14a1 1 0 0 0 1 1z" />{" "}
                <path d="M16 4l-4 4l-4 -4" /> <path d="M4 6.5l8 7.5l8 -7.5" />{" "}
              </svg>

              <p className="mx-1">concretefair@rvce.edu.in</p>
            </div>
          </div>
          <div className="flex md:flex-row flex-col items-center text-center  lg:w-5/6 w-full ">
            <div className="md:p-4 sm:w-1/2 w-full mx-2 my-2">
              <h2 className="md:mx-2 p-2 text-xl text-start">
                FACULTY COORDINATORS
              </h2>
              <div className="border-2 border-gray-500 md:px-4 px-2 flex flex-col py-4 bg-gray-200">
                <div className="px-4 md:px-2 py-2 flex flex-row justify-between items-center">
                  <h2 className="title-font font-medium text-lg text-gray-900 text-base">
                    Dr. Radhakrishna
                  </h2>
                  <p className="leading-relaxed text-red-500">+91 9886127398</p>
                </div>
                <div className="items-start mb-2 text-md">HoD Civil</div>
                <div className="px-4 md:px-2 py-2 flex flex-row justify-between items-center">
                  <h2 className="title-font font-medium text-lg text-gray-900">
                    Anand Kumar B G
                  </h2>
                  <p className="leading-relaxed text-red-500">+91 9844755956</p>
                </div>
                <div className="items-start mb-2 text-md">
                  Assistant Professor
                </div>
              </div>
            </div>

            <div className="md:p-4 sm:w-1/2 w-full mx-2 my-2">
              <h2 className="md:mx-2 p-2 text-xl text-start">
                STUDENT COORDINATORS
              </h2>
              <div className="border-2 border-gray-500 md:px-4 px-2 flex flex-col py-4 bg-gray-200">
                <div className="px-4 md:px-2 py-2 flex flex-row justify-between items-center">
                  <h2 className="title-font font-medium text-lg text-gray-900 text-base">
                    Ashwin Madhu
                  </h2>
                  <p className="leading-relaxed text-red-500">+91 8618825541</p>
                </div>
                <div className="items-start mb-2 text-md">VII Semester</div>
                <div className="px-4 md:px-2 py-2 flex flex-row justify-between items-center">
                  <h2 className="title-font font-medium text-lg text-gray-900">
                    M Adharsh R Nayak
                  </h2>
                  <p className="leading-relaxed text-red-500">+91 9108219577</p>
                </div>
                <div className="items-start mb-2 text-md">VII Semester</div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </div>
  );
};

export default Contact;
