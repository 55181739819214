import React from "react";
import { Link } from "react-router-dom";

const Flix = () => {
  return (
    <div className="flix-main-container">
      <section className="text-gray-600 body-font">
        <div className="container px-5 py-8 mx-auto md:w-5/6 flex flex-col justify-center items-center">
          <div className="lg:w-2/3 flex flex-col justify-center items-center mx-auto">
            <div className="header-container flex flex-row text-5xl font-bold mb-5">
              <div className="left-header-container font-sans px-8 pr-0 border-2 border-r-0 border-gray-700">
                Con
              </div>
              <div className="left-header-container font-sans text-white bg-gray-700 px-8 pl-0 py-1">
                FLIX
              </div>
            </div>
          </div>
          <div className="text-2xl font-semibold text-gray-700 border-b-2 border-gray-700 px-6 my-6">
            SHOOT AT SIGHT
          </div>

          <div className="rule-container flex flex-col m-5 mb-0 justify-center md:flex-row">
            <div className="inside-rule-container p-5 m-5 px-5 flex flex-col items-center border-2 border-gray-700 rounded-lg md:w-1/2 text-gray-700">
              <div className="text-xl text-gray-700 bg-white font-semibold p-2 rounded-sm my-2">
                Round 1
              </div>
              <p className="text-justify my-2 ">
                Each team is assigned with a particular theme( safety at field,
                wage gap at workplace, child labour at construction
                sites................................)
              </p>
            </div>
          </div>
        </div>
        <button className="button-register flex mx-auto m-5  text-white bg-gray-700 py-3 px-6 pt-2 rounded-lg text-xl text-center mt-0 font-bold">
        <Link to='https://forms.gle/XkuRi653c9Fii6BH8'>Register</Link>
        </button>
      </section>
    </div>
  );
};

export default Flix;
