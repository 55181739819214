import React from "react";
import con1 from "../images/con1.jpg";
import con2 from "../images/con2.jpg";
import con3 from "../images/con3.jpg";
import con4 from "../images/con4.jpg";
import con5 from "../images/con5.jpg";
import con6 from "../images/con6.jpg";
import con7 from "../images/con7.jpg";
import con8 from "../images/con8.jpg";
import con9 from "../images/con9.jpg";
import con10 from "../images/con10.jpg";
import { Link } from "react-router-dom";

const Events = () => {
  return (
    <div className="main-event-container font-inclusive bg-stone-200">
    
      <section id="event" className="text-gray-600 body-font">
        <div className="container px-5 py-10 mx-auto flex flex-col items-center">
          <div className="flex flex-wrap w-full mb-6">
            <div className=" w-full mb-6 lg:mb-0">
              <h1 className="text-3xl font-sans lg:text-5xl font-bold title-font text-gray-900 text-center tracking-wide">
                EVENTS
              </h1>
            </div>
          </div>
          <div className="flex flex-wrap -m-4 justify-start">
            <div className="xl:w-1/3 md:w-1/2 p-5">
            <Link to={"/events/sortium"}>
                <div className="bg-zinc-200 border border-gray-400 p-3 rounded-lg">
                  <img
                    className="h-40 rounded w-full object-cover object-center mb-6"
                    src={con1}
                    alt="content"
                  />
                  <h3 className="tracking-widest text-red-500 text-xs font-medium title-font">
                    11&12th Dec
                  </h3>
                  <h2 className="text-lg text-gray-900 font-medium title-font mb-4 flex justify-between">
                    CON.SORTIUM
                    <a
                      href="/events/sortium"
                      className="text-red-500 inline-flex items-center text-sm"
                    >
                      Register
                      <svg
                        fill="none"
                        stroke="currentColor"
                        stroke-linecap="round"
                        stroke-linejoin="round"
                        stroke-width="2"
                        className="w-4 h-4 "
                        viewBox="0 0 24 24"
                      >
                        <path d="M5 12h14M12 5l7 7-7 7"></path>
                      </svg>
                    </a>
                  </h2>
                  <p className="leading-relaxed text-base">
                    "Sell me this pen"
                  </p>
                </div>
              </Link></div>
            <div className="xl:w-1/3 md:w-1/2 p-4">
            <Link to={"/events/dwg"}>
                <div className="bg-zinc-200 border border-gray-400 p-3 rounded-lg">
                  <img
                    className="h-40 rounded w-full object-cover object-center mb-6"
                    src={con2}
                    alt="content"
                  />
                  <h3 className="tracking-widest text-red-500 text-xs font-medium title-font">
                  11&12th Dec
                  </h3>
                  <h2 className="text-lg text-gray-900 font-medium title-font mb-4 flex justify-between">
                    CON.DWG
                    <a
                      href=""
                      className="text-red-500 inline-flex items-center text-sm"
                    >
                      Register
                      <svg
                        fill="none"
                        stroke="currentColor"
                        stroke-linecap="round"
                        stroke-linejoin="round"
                        stroke-width="2"
                        className="w-4 h-4 "
                        viewBox="0 0 24 24"
                      >
                        <path d="M5 12h14M12 5l7 7-7 7"></path>
                      </svg>
                    </a>
                  </h2>
                  <p className="leading-relaxed text-base">
                    "The ultimate AutoCAD battle"
                  </p>
                </div>
              </Link></div>
            <div className="xl:w-1/3 md:w-1/2 p-4">
            <Link to={"/events/quest"}>
                <div className="bg-zinc-200 border border-gray-400 p-3 rounded-lg">
                  <img
                    className="h-40 rounded w-full object-cover object-center mb-6"
                    src={con3}
                    alt="content"
                  />
                  <h3 className="tracking-widest text-red-500 text-xs font-medium title-font">
                  11&12th Dec
                  </h3>
                  <h2 className="text-lg text-gray-900 font-medium title-font mb-4 flex justify-between">
                    CON.QUEST
                    <a
                      href=""
                      className="text-red-500 inline-flex items-center text-sm"
                    >
                      Register
                      <svg
                        fill="none"
                        stroke="currentColor"
                        stroke-linecap="round"
                        stroke-linejoin="round"
                        stroke-width="2"
                        className="w-4 h-4 "
                        viewBox="0 0 24 24"
                      >
                        <path d="M5 12h14M12 5l7 7-7 7"></path>
                      </svg>
                    </a>
                  </h2>
                  <p className="leading-relaxed text-base">
                    "Fastest finger first"
                  </p>
                </div>
              </Link></div>

            <div className="xl:w-1/3 md:w-1/2 p-4">
              <Link to={'/events/flix'}><div className="bg-zinc-200 border border-gray-400 p-3 rounded-lg">
                <img
                  className="h-40 rounded w-full object-cover object-center mb-6"
                  src={con4}
                  alt="content"
                />
                <h3 className="tracking-widest text-red-500 text-xs font-medium title-font">
                  11th&12th Dec
                </h3>
                <h2 className="text-lg text-gray-900 font-medium title-font mb-4 flex justify-between">
                  CON.FLIX
                  <a
                    href=""
                    className="text-red-500 inline-flex items-center text-sm"
                  >
                    Register
                    <svg
                      fill="none"
                      stroke="currentColor"
                      stroke-linecap="round"
                      stroke-linejoin="round"
                      stroke-width="2"
                      className="w-4 h-4 "
                      viewBox="0 0 24 24"
                    >
                      <path d="M5 12h14M12 5l7 7-7 7"></path>
                    </svg>
                  </a>
                </h2>
                <p className="leading-relaxed text-base">
                  "Shoot at Sight"
                </p>
              </div>
            </Link></div>
            <div className="xl:w-1/3 md:w-1/2 p-4">
              <Link to={'/events/figure'}><div className="bg-zinc-200 border border-gray-400 p-3 rounded-lg">
                <img
                  className="h-40 rounded w-full object-cover object-center mb-6"
                  src={con5}
                  alt="content"
                />
                <h3 className="tracking-widest text-red-500 text-xs font-medium title-font">
                  11th&12th Dec
                </h3>
                <h2 className="text-lg text-gray-900 font-medium title-font mb-4 flex justify-between">
                  CON.FIGURE
                  <a
                    href=""
                    className="text-red-500 inline-flex items-center text-sm"
                  >
                    Register
                    <svg
                      fill="none"
                      stroke="currentColor"
                      stroke-linecap="round"
                      stroke-linejoin="round"
                      stroke-width="2"
                      className="w-4 h-4 "
                      viewBox="0 0 24 24"
                    >
                      <path d="M5 12h14M12 5l7 7-7 7"></path>
                    </svg>
                  </a>
                </h2>
                <p className="leading-relaxed text-base">
                  "measuring success, one inch at a time"
                </p>
              </div>
            </Link></div>
            <div className="xl:w-1/3 md:w-1/2 p-4">
              <Link to={'/events/test'}><div className="bg-zinc-200 border border-gray-400 p-3 rounded-lg">
                <img
                  className="h-40 rounded w-full object-cover object-center mb-6"
                  src={con6}
                  alt="content"
                />
                <h3 className="tracking-widest text-red-500 text-xs font-medium title-font">
                  11th&12th Dec
                </h3>
                <h2 className="text-lg text-gray-900 font-medium title-font mb-4 flex justify-between">
                  CON.TEST
                  <a
                    href=""
                    className="text-red-500 inline-flex items-center text-sm"
                  >
                    Register
                    <svg
                      fill="none"
                      stroke="currentColor"
                      stroke-linecap="round"
                      stroke-linejoin="round"
                      stroke-width="2"
                      className="w-4 h-4 "
                      viewBox="0 0 24 24"
                    >
                      <path d="M5 12h14M12 5l7 7-7 7"></path>
                    </svg>
                  </a>
                </h2>
                <p className="leading-relaxed text-base">
                  "Cast to Last"
                </p>
              </div>
            </Link></div>
            <div className="xl:w-1/3 md:w-1/2 p-4">
              <Link to={'/events/fine'}><div className="bg-zinc-200 border border-gray-400 p-3 rounded-lg">
                <img
                  className="h-40 rounded w-full object-cover object-center mb-6"
                  src={con7}
                  alt="content"
                />
                <h3 className="tracking-widest text-red-500 text-xs font-medium title-font">
                  11th&12th Dec
                </h3>
                <h2 className="text-lg text-gray-900 font-medium title-font mb-4 flex justify-between">
                  CON.FINE
                  <a
                    href=""
                    className="text-red-500 inline-flex items-center text-sm"
                  >
                    Register
                    <svg
                      fill="none"
                      stroke="currentColor"
                      stroke-linecap="round"
                      stroke-linejoin="round"
                      stroke-width="2"
                      className="w-4 h-4 "
                      viewBox="0 0 24 24"
                    >
                      <path d="M5 12h14M12 5l7 7-7 7"></path>
                    </svg>
                  </a>
                </h2>
                <p className="leading-relaxed text-base">
                  DeCode IS Codes
                </p>
              </div>
            </Link></div>
            <div className="xl:w-1/3 md:w-1/2 p-4">
              <Link to={'/events/voke'}><div className="bg-zinc-200 border border-gray-400 p-3 rounded-lg">
                <img
                  className="h-40 rounded w-full object-cover object-center mb-6"
                  src={con8}
                  alt="content"
                />
                <h3 className="tracking-widest text-red-500 text-xs font-medium title-font">
                  11th&12th Dec
                </h3>
                <h2 className="text-lg text-gray-900 font-medium title-font mb-4 flex justify-between">
                  CON.VOKE
                  <a
                    href=""
                    className="text-red-500 inline-flex items-center text-sm"
                  >
                    Register
                    <svg
                      fill="none"
                      stroke="currentColor"
                      stroke-linecap="round"
                      stroke-linejoin="round"
                      stroke-width="2"
                      className="w-4 h-4 "
                      viewBox="0 0 24 24"
                    >
                      <path d="M5 12h14M12 5l7 7-7 7"></path>
                    </svg>
                  </a>
                </h2>
                <p className="leading-relaxed text-base">
                  "brainstorm, plan and save your city"
                </p>
              </div>
            </Link></div>
            <div className="xl:w-1/3 md:w-1/2 p-4">
              <Link to={'/events/x'}><div className="bg-zinc-200 border border-gray-400 p-3 rounded-lg">
                <img
                  className="h-40 rounded w-full object-cover object-center mb-6"
                  src={con9}
                  alt="content"
                />
                <h3 className="tracking-widest text-red-500 text-xs font-medium title-font">
                  11th&12th Dec
                </h3>
                <h2 className="text-lg text-gray-900 font-medium title-font mb-4 flex justify-between">
                  CON.X
                  <a
                    href=""
                    className="text-red-500 inline-flex items-center text-sm"
                  >
                    Register
                    <svg
                      fill="none"
                      stroke="currentColor"
                      stroke-linecap="round"
                      stroke-linejoin="round"
                      stroke-width="2"
                      className="w-4 h-4 "
                      viewBox="0 0 24 24"
                    >
                      <path d="M5 12h14M12 5l7 7-7 7"></path>
                    </svg>
                  </a>
                </h2>
                <p className="leading-relaxed text-base">
                  "match the RL or take the L"
                </p>
              </div>
            </Link></div>
            <div className="xl:w-1/3 md:w-1/2 p-4">
              <Link to={'/events/strain'}><div className="bg-zinc-200 border border-gray-400 p-3 rounded-lg">
                <img
                  className="h-40 rounded w-full object-cover object-center mb-6"
                  src={con10}
                  alt="content"
                />
                <h3 className="tracking-widest text-red-500 text-xs font-medium title-font">
                  11th&12th Dec
                </h3>
                <h2 className="text-lg text-gray-900 font-medium title-font mb-4 flex justify-between">
                  CON.STRAIN
                  <a
                    href=""
                    className="text-red-500 inline-flex items-center text-sm"
                  >
                    Register
                    <svg
                      fill="none"
                      stroke="currentColor"
                      stroke-linecap="round"
                      stroke-linejoin="round"
                      stroke-width="2"
                      className="w-4 h-4 "
                      viewBox="0 0 24 24"
                    >
                      <path d="M5 12h14M12 5l7 7-7 7"></path>
                    </svg>
                  </a>
                </h2>
                <p className="leading-relaxed text-base">
                  "Career Quest, who's next?"
                </p>
              </div>
            </Link></div>
          </div>
        </div>
      </section>
    </div>
  );
};

export default Events;
