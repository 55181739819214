import React from "react";
import { Link } from "react-router-dom";
import rvlogo from "../images/header icons/logo.jpeg";
import ascelogo from "../images/header icons/ascelogo.jpg";
import icilogo from '../images/header icons/ici.jpg';
import './Header.css';


const Header = () => {
  return (
    <div className="main-header-container">
      <section className="text-white body-font">
        <div className="flex md:px-5 py-4 bg-zinc-800 flex-row md:justify-between items-center z-10 px-2 justify-between">
          
            <div className="rounded-full flex title-font font-medium items-center">
            <img
              className="w-10 h-10 text-white rounded-full lg:mr-9 md:mr-5"
              src={rvlogo}
              alt="rv"
            />
            </div>
         
          <nav className="md:ml-auto md:mr-auto font-medium flex flex-wrap items-center text-base justify-center z-10 ">
            <Link to={'/'} className=" md:text-xl header-list px-2 py-2 pt-1.5 rounded-lg md:mx-4 mx-2 text-zinc-100 ">Home</Link>
            <Link to={'/events'} className="md:text-xl header-list px-2 py-2 pt-1.5 rounded-lg md:mx-4 mx-2 text-zinc-100 ">Events</Link>
            <Link to={'/contact'} className="md:text-xl header-list px-2 py-2 pt-1.5 rounded-lg md:mx-4 mx-2 text-zinc-100 hidden md:block ">Contact</Link>
          </nav>
          <div className="flex flex-row">
          <div className="ascelogo-container bg-white font rounded-full ml-2 md:ml-3 flex items-center justify-center">
            <img
              className="w-8 h-8 text-white z-10"
              src={ascelogo}
              alt="rv"
            />
          </div>
          <div className="flex font rounded-full items-center justify-center ml-2 md:ml-3">
            <img
              className="w-9 h-9 text-white z-10"
              src={icilogo}
              alt="rv"
            />
          </div>
          </div>
        </div>
      </section>
    </div>
  );
};

export default Header;
